<template>
  <div>
    <!-- 底部区域 -->
    <div class="footer-wrap">
      <div class="top">
          <img src="@/assets/icon/black.svg" alt="" />
        <p>MetaWords</p>
      </div>
      <span @click="footJump(1)"  :style="{ fontFamily: flag == 1 ? 'Montserrat-Medium' : 'NotoSansSC-Medium' }">{{
        flag == 1 ? "Marketplace" : "交易市场"
      }}</span>
      <span @click="footJump(2)"   :style="{ fontFamily: flag == 1 ? 'Montserrat-Medium' : 'NotoSansSC-Medium' }">{{
        flag == 1 ? "About Us" : "关于我们"
      }}</span>
      <span @click="footJump(3)"   :style="{ fontFamily: flag == 1 ? 'Montserrat-Medium' : 'NotoSansSC-Medium' }">{{
        flag == 1 ? "Become a Partner" : "成为原住民"
      }}</span>

      <div class="bottom">
        <img @click="footJump(4)" src="@/assets/icon/bottom-logo.png" alt="" />
        <img @click="footJump(5)" src="@/assets/icon/bottom-logo2.png" alt="" />
        <img @click="footJump(6)" src="@/assets/icon/bottom-logo3.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flag: {
      type: Number,
      default: () => {
        return 0
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    // 底部跳转
    footJump(num) {
      switch (num) {
        case 1:
					// window.open("https://lionnft.net/register");
          break;
        case 2:
          this.$router.push({
          path: "./About",
          query: {
            flag: this.flag
          }
        });
          break;
        case 3:
          this.$router.push({
          path: "./aborigines",
          query: {
            flag: this.flag
          }
        });
          break;
        case 4:
          window.open("https://t.me/MetaWordsNFTDiscussion");
          break;
        case 5:
          window.open("https://twitter.com/MetaWordsNFT");
          break;
        case 6:
          window.open("https://discord.com/invite/FFmUt3rp5J");
          break;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
// 底部区域样式
.footer-wrap {
  padding: 25px 0 0 26px;
  width: 100%;
  height: 387px;
  background: #000000;
  box-sizing: border-box;
  .top {
    display: flex;
    align-items: center;
    img {
      width: 63px;
      height: 63px;
    }
    p {
      margin-left: 9px;
      width: 174px;
      height: 30px;
      font-size: 24px;
      font-family: Montserrat-Bold, Montserrat;
      font-weight: bold;
      color: #ffffff;
      line-height: 29px;
    }
  }
  span {
    margin-top: 21px;
    display: block;
    height: 24px;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 24px;
    -webkit-background-clip: text;
  }
  .bottom {
    margin-top: 39px;
    img {
      margin-right: 20px;
      width: 41px;
      height: 41px;
    }
  }
}
</style>