<template>
  <router-view/>
</template>
<script>

export default {
  data() {
    return {

    }
  },
  
}
</script>


<style lang="scss">
*{
  line-height: 1;
}
html,body{
  margin: 0;
  padding: 0;
}
</style>
