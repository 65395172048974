<template>
  <div class="home">
    <!-- 标题区域 -->
    <div class="title-wrap">
      <span @click="language_active('english')">English</span>
      <span @click="language_active('chinese')">中文</span>
    </div>
    <!-- 导航区域 -->
    <nav-bar @navBtn="navBtn" :classNum="0" :flag="flag" />
    <!-- 菜单导航区域 -->
    <menu-page :flag="flag" v-show="menuShow == 1" />
    <div v-show="menuShow == 0">
      <!-- 背景区域 -->
      <div class="bg-wrap">
        <img src="@/assets/icon/bg.png" alt="" />
      </div>
      <!-- 简介区域 -->
      <div class="presentation-wrap">
        <span :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Medium' }">{{ flag == 1 ? textActive : text }}</span>
      </div>
      <!-- 按钮区域 -->
      <div class="btn-wrap">
        <div class="btn" @click="see_btn">
          <span :style="{ fontFamily: flag == 1 ? 'Montserrat-Bold' : 'NotoSansSC-Bold' }">
            {{ flag == 1 ? "Discover MetaWordsNFT" : "查看MetaWordsNFT" }}</span
          >
          <img src="@/assets/icon/btnRight.png" alt="" />
        </div>
      </div>

      <!-- 展示内容区域 -->

      <div class="show-content-wrap">
        <!-- 图标区域 -->
        <div class="icon">
          <img src="@/assets/icon/group-icon.png" alt="" />
        </div>
        <!-- 标题区域 -->
        <div class="title">
          <span :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Medium' }">{{
            flag == 1
              ? "Preview of the first 30 characters up for auction."
              : "拍卖字符预览"
          }}</span>
        </div>
        <!-- 轮播图区域 -->
        <div class="slideshow">
          <div
            class="content-wrap"
            :class="{ 'content-wrap-active': flag == 1 }"
            @click="slideshow_jump(1)"
          >
            <div class="top">
              <span :style="{ fontFamily: flag == 1 ? 'Montserrat-Bold' : 'NotoSansSC-Bold' }">{{
                flag == 1 ? "Get to Know MetaWords(I)" : "MetaWords小讲堂(一)"
              }}</span>
            </div>
            <div class="center">
              <img src="https://lionworld.oss-cn-hongkong.aliyuncs.com/metawords/source-1.png" alt="" />
              <img src="@/assets/icon/play-buttom.png" alt="" />
            </div>
            <div class="bottom">
              <img src="@/assets/icon/title-icon.png" alt="" />
              <div class="text">
                <div :style="{ fontFamily: flag == 1 ? 'Montserrat-Bold' : 'NotoSansSC-Medium' }">
                  {{ flag == 1 ? "What Is MetaWords" : "什么是MetaWords？" }}
                </div>
                <div>03:29</div>
              </div>
            </div>
          </div>
          <div
            class="content-wrap"
            :class="{ 'content-wrap-active': flag == 1 }"
            @click="slideshow_jump(2)"
          >
            <div class="top">
              <span :style="{ fontFamily: flag == 1 ? 'Montserrat-Bold' : 'NotoSansSC-Bold' }">{{
                flag == 1 ? "Get to Know MetaWords(II)" : "MetaWords小讲堂(二)"
              }}</span>
            </div>
            <div class="center">
              <img src="https://lionworld.oss-cn-hongkong.aliyuncs.com/metawords/source-3.png" alt="" />
              <img src="@/assets/icon/play-buttom.png" alt="" />
            </div>
            <div class="bottom">
              <img class="bottom-img" src="@/assets/icon/slideshow-icon2.png" alt="" />
              <div class="text">
                <div :style="{ fontFamily: flag == 1 ? 'Montserrat-Bold' : 'NotoSansSC-Medium' }">{{ flag == 1 ? "Economic Model" : "经济模型" }}</div>
                <div>03:29</div>
              </div>
            </div>
          </div>
          <div
            class="content-wrap"
            :class="{ 'content-wrap-active': flag == 1 }"
            @click="slideshow_jump(3)"
          >
            <div class="top">
              <span :style="{ fontFamily: flag == 1 ? 'Montserrat-Bold' : 'NotoSansSC-Bold' }">{{
                flag == 1 ? "Get to Know MetaWords(III)" : "MetaWords小讲堂(三)"
              }}</span>
            </div>
            <div class="center">
              <img src="https://lionworld.oss-cn-hongkong.aliyuncs.com/metawords/source-2.png" alt="" />
              <img src="@/assets/icon/play-buttom.png" alt="" />
            </div>
            <div class="bottom">
              <img
                style="height: 45px; width: 35px"
                src="@/assets/icon/slideshow-icon3.png"
                alt=""
              />
              <div class="text">
                <div :style="{ fontFamily: flag == 1 ? 'Montserrat-Bold' : 'NotoSansSC-Medium' }">
                  {{
                    flag == 1
                      ? "How to Purchase"
                      : "如何购买字符和作品"
                  }}
                </div>
                <div>03:29</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 白皮书区域 -->
        <div class="white-book-wrap">
          <!-- 白皮书标题 -->
          <div class="titles">
            <span :style="{ fontFamily: flag == 1 ? 'Montserrat-Bold' : 'Montserrat-Bold' }">{{
              flag == 1 ? "MetaWords White Paper" : "MetaWords白皮書"
            }}</span>
          </div>
          <!-- 白皮书内容 -->
          <div class="book">
            <img src="@/assets/icon/white-book.png" alt="" />
            <div class="right">
              <!-- <div @click="pdfBtn(1)" :style="{ fontFamily: flag == 1 ? 'NotoSansSC-Medium' : 'NotoSansSC-Medium' }">
                <img src="@/assets/icon/down-icon.png" alt="" />白皮书下载
              </div>
              <div @click="pdfBtn(2)" :style="{ fontFamily: flag == 1 ? 'NotoSansSC-Medium' : 'NotoSansSC-Medium' }">
                <img src="@/assets/icon/down-icon.png" alt="" />简册下载
              </div> -->
              <div @click="pdfBtn(3)" :style="{ fontFamily: flag == 1 ? 'Montserrat-Medium' : 'Montserrat-Medium' }">
                <img src="@/assets/icon/down-icon.png" alt="" />English White
                Paper
              </div>
              <div @click="pdfBtn(4)" :style="{ fontFamily: flag == 1 ? 'Montserrat-Medium' : 'Montserrat-Medium' }">
                <img src="@/assets/icon/down-icon.png" alt="" />English Brochure
              </div>
            </div>
          </div>

          <!-- 白皮书简介 -->
          <div class="bottom"  :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Medium' }">
            {{ flag == 1 ? whiteBookTextActive : whiteBookText }}
          </div>
        </div>
      </div>

      <!-- 路线图区域 -->
      <div class="roadmap-wrap">
        <div class="road-title">
          <img src="@/assets/icon/down-icon.png" alt="" />Roadmap
        </div>
        <div class="road-text">
          {{ flag == 1 ? roadmapTextActive : roadmapText }}
        </div>
        <div class="bottom">
          <img src="@/assets/icon/road-icon.png" alt="" />
          <div class="right">
            <span style="fontFamily:Montserrat-Regular" v-if="flag == 1"
              >MetaWords release. The first NFT bundle will be up for
              auction.</span
            >
            <span v-else style="fontFamily:NotoSansSC-Medium">MetaWords上线，首批NFT进行拍卖</span>
            <span style="fontFamily:Montserrat-Regular" v-if="flag == 1"
              >Lion NFT Platform will launch a MetaWords NFT creation-tool,
              giving users the ability to create new MetaWords NFTs as well as
              trade them on the Lion NFT platform.</span
            >
            <span v-else
            style="fontFamily:NotoSansSC-Medium"
              >Lion
              NFT平台将启动创作功能，所有用户可用MetaWords进行行创作生成全新内容的NFT，并在Lion
              NFT平台进行二次转让</span
            >
            <span style="fontFamily:Montserrat-Regular" v-if="flag == 1">Blind box release.</span>
            <span v-else style="fontFamily:NotoSansSC-Medium">启动盲盒销售</span>
            <span style="fontFamily:Montserrat-Regular" v-if="flag == 1"
              >MetaWords connects to Metaverse platforms.</span
            >
            <span v-else style="fontFamily:NotoSansSC-Medium">MetaWords接入元宇宙场景</span>
            <span style="fontFamily:Montserrat-Regular" v-if="flag == 1"
              >MetaWords engages with social networks. Users can showcase their
              NFT works across a variety of platforms and can interact with
              other users using MetaWords.</span
            >
            <span v-else
            style="fontFamily:NotoSansSC-Medium"
              >MetaWords接入社交及展示场景，用户可展示NFT作品,同时用MetaWords与其他人交流</span
            >
            <span style="fontFamily:Montserrat-Regular" v-if="flag == 1"
              >MetaWords invites inhabitants of the Metaverse to take part in
              contributing to the MetaWords lexicon.</span
            >
            <span v-else style="fontFamily:NotoSansSC-Medium">MetaWords允许元宇宙居民参与设计字符,并进行创作</span>
            <span style="fontFamily:Montserrat-Regular" v-if="flag == 1"
              >MetaWords launches the language translator. MetaWords characters
              support input using any of the world’s major languages.</span
            >
            <span v-else
            style="fontFamily:NotoSansSC-Medium"
              >MetaWords推出语言转化器，任何人使用主流语言可以打出MetaWords字符</span
            >
          </div>
        </div>
      </div>

      <!-- 底部区域 -->
      <div
        :class="flag == 1 ? 'bottom-active-wrap' : 'bottom-wrap'"
      >
        <div class="bg">
          <img
            src="@/assets/icon/bottom-bg2.png"
            alt=""
          />
          <div class="bg-contetn">
            <img src="@/assets/icon/down-icon.png" alt="" />
            <span class="title-bottom">{{
              flag == 1 ? "MetaWords Inhabitant Program" : "MetaWords原住民计划"
            }}</span>
            <p :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Medium' }">{{ flag == 1 ? bottomTextActive : bottomText }}</p>
            <div
              @click="seeDetailBtn"
              :style="{ marginTop: flag == 1 ? '0' : '50px', fontFamily: flag == 1 ? 'Montserrat-Medium' : 'NotoSansSC-Bold' }"
            >
              {{ flag == 1 ? "View Details" : "查看详情" }}
            </div>
          </div>
        </div>
      </div>

      <!-- 社群区域 -->
      <div class="association-wrap">
        <div class="top">
          <div class="left">
            <img src="@/assets/icon/association-icon.png" alt="" />
            <span :style="{ fontFamily: flag == 1 ? 'Montserrat-Bold' : 'NotoSansSC-Bold' }">{{ flag == 1 ? "Join the Community" : "加入社群" }}</span>
          </div>
          <div class="right">
            <img src="@/assets/icon/association-icon2.png" alt="" />
          </div>
        </div>
        <div class="center" :style="{ fontFamily: flag == 1 ? 'Montserrat-Regular' : 'NotoSansSC-Regular' }">
          {{ flag == 1 ? associationTextActive : associationText }}
        </div>
        <div class="bottom">
          <img
            @click="bottom_icon_jump(1)"
            src="@/assets/icon/association-icon3.png"
            alt=""
          />
          <img
            @click="bottom_icon_jump(2)"
            src="@/assets/icon/association-icon4.png"
            alt=""
          />
          <img
            @click="bottom_icon_jump(3)"
            src="@/assets/icon/association-icon5.png"
            alt=""
          />
        </div>
      </div>
      <bottom :flag="flag" />
    </div>
  </div>
</template>

<script>
import navBar from "@/components/navBar.vue";
import menuPage from "@/components/menuPage.vue";
import bottom from "@/components/footer.vue";
export default {
  components: {
    navBar,
    menuPage,
    bottom,
  },
  data() {
    return {
      flag: 0, //语言转换
      menuShow: 0,
      text: "MetaWords, 艺术家徐冰的作品“地书”以NFT的形式在元宇宙（Metaverse）当中的延展，旨在打造未来元宇宙中全新的语言体系，作为元宇宙当中的元语言，随着元宇宙一起不断发展，实现普天同文的理想。",
      textActive:
        "MetaWords, an NFT art project, is the evolution of Xu Bing’s art-work Book from the Ground in the Metaverse. MetaWords aims to invent an entirely new language system of the future Metaverse–The “Meta-language” of the Metaverse–to achieve Xu Bing’s ideal of a universal script.",
      whiteBookText:
        "白皮书是飞狮工作室制作的MetaWords官方指导文件，你可以在这里找到你对MetaWords大部分疑问的解答。",
      whiteBookTextActive:
        "The MetaWords White Paper is an important guiding document in the development of MetaWords provided by Flying Lion Lab. Here, you can find out the basics about MetaWords as well as answers to any questions you may have.",
      roadmapText:
        "MetaWords更加精彩的功能，正在紧锣密鼓的筹备中，会在未来呈现给大家。",
      roadmapTextActive:
        "Roadmap outlines MetaWords’ vision and exciting features that we are currently working on. We hope it iterate and grow over time alongside our community.",
      bottomText:
        "为了能给参与到MetaWords建设的小伙伴们更好的福利，我们高兴地宣布正式启动MetaWords原住民计划，希望您能在充满热情的市场行情下、在MetaWords美好的发展前景下，同我们一起并肩作战，实现共赢。期待你们的加入！",
      bottomTextActive:
        "We are pleased to announce the official launch of the MetaWords Inhabitant Program which serves as a coordinated effort to provide better resources to our community and friends who continue to support and grow with MetaWords. We look forward to having more of you join the community and contribute to a thriving new market with the promising development of MetaWords We look forward to having you join us!",
      associationText:
        "在MetaWords自己的社群里，你可以找到最新资讯，常见问题的解答，并和其他的原住民们交流！",
      associationTextActive:
        "In the MetaWords Community, you can find the latest updates and answers to frequently asked questions, as well as connect with other inhabitants.",
    };
  },
  mounted() {
    const language = localStorage.getItem('locale') || window.navigator.language.toLowerCase() || 'en'
    if (language.indexOf("zh-") !== -1) {
      if(this.$route.query.flag != undefined) {
        this.flag = Number(this.$route.query.flag)
      }else {
        this.flag = 2
      }
    }else if(language.indexOf('en') !== -1) {
      if(this.$route.query.flag != undefined) {
        this.flag = Number(this.$route.query.flag)
      }else {
        this.flag = 1
      }
    }else {
     if(this.$route.query.flag != undefined) {
        this.flag = Number(this.$route.query.flag)
      }else {
        this.flag = 1
      }
    }
   
  },
  methods: {
    // 中英文切换
    language_active(type) {
      if (type == "english") {
        this.flag = 1;
      } else {
        this.flag = 2;
      }
    },
    // 导航区域
    navBtn(data) {
      this.menuShow = data;
    },
    // 查看按钮
    see_btn() {
      // window.open("https://lionnft.net/");
    },
    // pdf 预览按钮
    pdfBtn(num) {
      if (num == 1) {
        window.open("https://metawords.s3.ap-southeast-1.amazonaws.com/pdf/MetaWords%E7%99%BD%E7%9A%AE%E4%B9%A6.pdf");
      } else if (num == 2) {
        window.open("https://metawords.s3.ap-southeast-1.amazonaws.com/pdf/MetaWords%E6%A8%AA%E7%89%88%E4%B8%AD%E6%96%87%E4%BB%8B%E7%BB%8DVer1.pdf");
      } else if (num == 3) {
        window.open("https://metawords.s3.ap-southeast-1.amazonaws.com/pdf/MetaWords+WHITE+PAPER.pdf");
      } else if (num == 4) {
        window.open("https://metawords.s3.ap-southeast-1.amazonaws.com/pdf/MetaWords+Eng+Intro+Dec.pdf");
      }
    },
    // 轮播图跳转
    slideshow_jump(num) {
      if (num == 1) {
        window.open("https://metawords.s3.ap-southeast-1.amazonaws.com/What+Is+MetaWords.mp4");
      } else if (num == 2) {
        window.open("https://metawords.s3.ap-southeast-1.amazonaws.com/Economic+Model.mp4");
      } else if (num == 3) {
        window.open("https://metawords.s3.ap-southeast-1.amazonaws.com/How+to+Purchase.mp4");
      }
    },
    // 查看详情
    seeDetailBtn() {
      window.open("https://lionnft.net/inhabitant");
    },
    // 底部图标跳转
    bottom_icon_jump(num) {
      if (num == 1) {
        window.open("https://twitter.com/MetaWordsNFT");
      } else if (num == 2) {
        window.open("https://t.me/MetaWordsNFTDiscussion");
      } else if (num == 3) {
        window.open("https://discord.com/invite/FFmUt3rp5J");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 标题区域样式
.title-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 22px;
  height: 30px;
  background: #000000;
  span {
    width: 89px;
    text-align: right;
    font-size: 18px;
    font-family: Montserrat-Regular;
    font-weight: 400;
    color: #ffffff;
  }
}

// 背景区域样式
.bg-wrap {
  padding: 0 20px;
  img {
    height: 451px;
    width: 100%;
  }
}

// 简介区域样式
.presentation-wrap {
  padding: 0 22px;
}
.presentation-wrap span {
    display: inline-block;
    margin-top: 20px;
    width: 367px;
    height: 141px;
    font-size: 18px;
    font-weight: 550;
    color: #000000;
    line-height: 27px;
  }
// 按钮区域样式
.btn-wrap {
  margin-top: 20px;
  padding: 0 22px;
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 363px;
    height: 53px;
    text-align: center;
    line-height: 53px;
    background: #000000;
    border-radius: 7px;
    span {
      font-size: 21px;
      font-weight: bold;
      color: #ffffff;
    }
    img {
      margin-left: 12px;
      height: 20px;
      width: 35px;
    }
  }
}

// 展示内容区域样式
.show-content-wrap {
  background: #ffffff;
  box-shadow: 0px -1px 20px 14px rgba(0, 0, 0, 0.09);
  .jiange {
    margin-left: 50px;
    margin-top: 20px;
    width: 100px;
    box-shadow: 0px -1px 20px 14px rgba(0, 0, 0, 0.09);
  }
  .icon {
    margin-top: 50px;
    padding: 50px 44px 0;
    height: 327px;
    width: 272px;
    img {
      height: 280px;
      width: 320px;
    }
  }
  // 标题区域样式
  .title {
    margin: 0 44px;
    text-align: center;
    height: 70px;
    border-bottom: 3px solid #000000;
    span {
      width: 171px;
      height: 30px;
      font-size: 21px;
      font-weight: 550;
      color: #000000;
      line-height: 30px;
      -webkit-background-clip: text;
    }
  }

  // 轮播图区域
  .slideshow {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
    padding: 0 44px;
    overflow-x: scroll;
    .content-wrap-active {
      height: 360px;
    }
    .content-wrap {
      margin: 10px 20px 10px 0;
      width: 264px;
      background: #ffffff;
      box-shadow: 0px -1px 20px 14px rgba(0, 0, 0, 0.06);
      border-radius: 17px;
      .top {
        text-align: center;
        span {
          width: 243px;
          height: 32px;
          font-size: 21px;
          font-weight: bold;
          color: #000000;
          line-height: 32px;
          -webkit-background-clip: text;
        }
      }
      .center {
        position: relative;
        img:nth-child(1) {
          height: 200px;
          width: 264px;
        }
        img:nth-child(2) {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          height: 85;
          width: 85px;
          border-radius: 50%;
        }
      }
      .bottom {
        display: flex;
        padding-left: 7px;
        margin-top: 15px;
        img {
          margin-right: 10px;
          height: 35px;
          width: 60px;
        }
        .text {
          div:nth-child(1) {
            font-size: 16px;
            font-weight: 550;
            color: #000000;
            line-height: 22px;
            -webkit-background-clip: text;
          }
          div:nth-child(2) {
            font-size: 14px;
            color: #000000;
            line-height: 32px;
            -webkit-background-clip: text;
          }
        }
        .bottom-img {
          margin-right: 10px;
          height: 35px;
          width: 40px;
        }
      }
    }
  }

  // 白皮书区域样式
  .white-book-wrap {
    margin-top: 20px;
    padding: 0 44px;
    .titles {
      text-align: center;
      span {
        font-size: 22px;
        font-weight: bold;
        color: #000000;
        line-height: 60px;
      }
    }
    .book {
      display: flex;
      img {
        height: 140px;
        width: 100px;
      }
      .right {
        margin-left: 15px;
        div {
          margin-bottom: 16px;
          font-size: 16px;
          font-weight: 550;
          color: #000000;
          line-height: 26px;
          img {
            height: 14px;
            width: 14px;
            margin-right: 8px;
          }
        }
        div:nth-child(1) {
          margin-top: 40px;
        }
      }
    }
    .bottom {
      padding-bottom: 20px;
      width: 330px;
      font-size: 16px;
      font-family: NotoSansSC-Medium;
      font-weight: 550;
      color: #231916;
      line-height: 26px;
    }
  }
}

// 路线图区域样式
.roadmap-wrap {
  padding: 50px 23px 0;
  .road-title {
    text-align: center;
    font-size: 26px;
    font-family: Montserrat-Bold;
    font-weight: bold;
    color: #000000;
    img {
      width: 22px;
      height: 22px;
      margin-right: 12px;
    }
  }
  .road-text {
    margin: 40px 0 20px 0;
    font-size: 16px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 550;
    color: #231916;
    line-height: 26px;
  }
  .bottom {
    display: flex;
    img {
      margin-right: 12px;
      height: 541px;
      width: 81px;
    }
    .right {
      span {
        display: block;
        width: 268px;
        height: 20px;
        font-size: 14px;
        font-family: NotoSansSC-Medium;
        font-weight: 550;
        color: #231916;
        line-height: 20px;
      }
      span:nth-child(1) {
        margin-top: 35px;
      }
      span:nth-child(2) {
        margin-top: 50px;
      }
      span:nth-child(3) {
        margin-top: 90px;
      }
      span:nth-child(4) {
        margin-top: 20px;
      }
      span:nth-child(5) {
        margin-top: 35px;
      }
      span:nth-child(6) {
        margin-top: 75px;
      }
      span:nth-child(7) {
        margin-top: 55px;
      }
    }
  }
}

// 底部区域样式
.bottom-wrap {
  margin-top: 50px;
  height: 563px;
  width: 100%;
  background: url("../assets/icon/bottom-bg.png"), 100%, no-repeat;
  box-shadow: 0px -1px 20px 14px rgba(0, 0, 0, 0.09);
  
}
.bottom-wrap .bg {
    position: relative;
    padding: 36px 23px 56px 35px;
    img {
      height: 470px;
      width: 348px;
    }
    .bg-contetn {
      position: absolute;
      top: 14%;
      left: 15%;
      img {
        margin-right: 14px;
        margin-top: 6px;
        height: 26px;
        width: 25px;
        vertical-align: top;
      }
      span {
        display: inline-block;
        width: 228px;
        height: 120px;
        font-size: 26px;
        font-family: Montserrat-Bold, Montserrat;
        font-weight: bold;
        color: #000000;
        line-height: 32px;
      }
      p {
        margin-top: -20px;
        width: 275px;
        font-size: 18px;
        font-family: NotoSansSC-Medium, NotoSansSC;
        font-weight: 550;
        color: #231916;
        line-height: 22px;
      }
      div {
        margin-top: 30px;
        text-align: center;
        font-size: 18px;
        font-family: NotoSansSC-Bold;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.52);
        line-height: 26px;
      }
    }
  }
.bottom-active-wrap {
  margin-top: 50px;
  height: 700px;
  width: 100%;
  background: url("../assets/icon/bottom-bg.png"), 100%, no-repeat;
  box-shadow: 0px -1px 20px 14px rgba(0, 0, 0, 0.09);
  .bg {
    position: relative;
    padding: 36px 23px 56px 35px;
    img {
      height: 620px;
      width: 348px;
    }
    .bg-contetn {
      position: absolute;
      top: 14%;
      left: 15%;
      img {
        margin-right: 14px;
        margin-top: 6px;
        height: 26px;
        width: 25px;
        vertical-align: top;
      }
      span {
        display: inline-block;
        width: 228px;
        height: 120px;
        font-size: 26px;
        font-family: Montserrat-Bold, Montserrat;
        font-weight: bold;
        color: #000000;
        line-height: 32px;
      }
      p {
        margin-top: -20px;
        width: 275px;
        font-size: 18px;
        font-family: NotoSansSC-Medium, NotoSansSC;
        font-weight: 550;
        color: #231916;
        line-height: 22px;
      }
      div {
        margin-top: 30px;
        text-align: center;
        font-size: 18px;
        font-family: NotoSansSC-Bold;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.52);
        line-height: 26px;
      }
    }
  }
}

// 社群区域样式
.association-wrap {
  padding-top: 66px;
  height: 417px;
  background: #000000;
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 39px 0 34px;
    .left {
      img {
        margin-right: 12px;
        height: 30px;
        width: 29px;
      }
      span {
        width: 153px;
        height: 37px;
        font-size: 26px;
        font-family: NotoSansSC-Bold, NotoSansSC;
        font-weight: bold;
        color: #ffffff;
        line-height: 37px;
      }
    }
    .right {
      img {
        height: 90px;
        width: 90px;
      }
    }
  }
  .center {
    margin-top: 60px;
    padding: 0 24px 0 25px;
    font-size: 17px;
    font-family: NotoSansSC-Regular;
    font-weight: 400;
    color: #ffffff;
    line-height: 26px;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 89px 0 87px;
    margin-top: 30px;
    img {
      height: 52px;
      width: 51px;
    }
  }
}
</style>
