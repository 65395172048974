<template>
  <div class="nav-bar-wrap">
    <!-- logo菜单区域 -->
    <div class="logo-menu-wrap">
      <img @click="logoBtn" src="@/assets/icon/white.svg" alt="" />
      <img
        v-show="menuShow == 0"
        @click="menu_btn(0)"
        src="@/assets/icon/menu.png"
      />
      <img
        v-show="menuShow == 1"
        @click="menu_btn(1)"
        src="@/assets/icon/menu-active.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuShow: 0,
    };
  },
  methods: {
		logoBtn() {
			this.$router.push({
        path: '/',
        query: {
            flag: this.flag
          }
      })
		},
    menu_btn(num) {
      if (num == 0) {
        this.menuShow = 1;
      } else if (num == 1) {
        this.menuShow = 0;
      }
			this.$emit('navBtn', this.menuShow)
    },
  },
};
</script>

<style lang="scss" scoped>
// logo 菜单区域样式
.logo-menu-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 22px;
  img:nth-child(1) {
    width: 53px;
    height: 53px;
  }
  img:nth-child(2) {
    width: 42px;
    height: 42px;
  }
  img:nth-child(3) {
    width: 42px;
    height: 42px;
  }
  img:nth-child(2):hover {
    background: url("../assets/icon/menu-active.png") no-repeat 42px 42px;
  }
}
</style>