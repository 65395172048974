import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/Book',
    name: 'Book',
    component: () => import('../views/Book.vue')
  },
  {
    path: '/aborigines',
    name: 'aborigines',
    component: () => import('../views/Aborigines.vue')
  }, {
    path: '/About',
    name: 'About',
    component: () => import('../views/About.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 判断是否是移动端访问  不是移动端访问mobile的值为null
  const mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  if (mobile) next()
  else window.location.href = "https://metawords.io/#/Home"
})

export default router
