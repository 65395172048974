<template>
  <div class="menu-page-wrap">
    <div class="menu-active-wrap">
      <span @click="jumpBtn(0)" :class="{ 'colorActive': classNum == 0 }"  :style="{ fontFamily: flag == 1 ? 'Montserrat-ExtraBold,' : 'NotoSansSC-Black' }">{{ flag == 1? 'HOME' : '首页'}}</span>
      <span @click="jumpBtn(1)" :class="{ 'colorActive': classNum == 1 }"  :style="{ fontFamily: flag == 1 ? 'Montserrat-ExtraBold,' : 'NotoSansSC-Black' }">{{ flag == 1? 'BOOK FROM THE GROUND' : '地书'}}</span>
      <span @click="jumpBtn(2)" :class="{ 'colorActive': classNum == 2 }"  :style="{ fontFamily: flag == 1 ? 'Montserrat-ExtraBold,' : 'NotoSansSC-Black' }">{{  flag == 1? 'METAWORDS INHABITANT PROGRAM' : '原住民计划'}}</span>
      <span style="fontFamily: Montserrat-ExtraBold" @click="jumpBtn(3)">Telegram</span>
      <span style="fontFamily: Montserrat-ExtraBold" @click="jumpBtn(4)">Discord</span>
      <span style="fontFamily: Montserrat-ExtraBold" @click="jumpBtn(5)">Twitter</span>
      <span style="fontFamily: Montserrat-ExtraBold" @click="jumpBtn(6)">Lion NFT</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flag: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {
      classNum: 0,
    };
  },
  methods: {
    jumpBtn(num) {
      this.classNum = num
		if(num ==0) {
      this.$router.push({
          path: '/',
          query: {
            flag: this.flag
          }
        })
		  }else if(num == 1) {
        this.$router.push({
          path: './Book',
          query: {
            flag: this.flag
          }
        })
      }else if(num == 2) {
        this.$router.push({
          path: './aborigines',
          query: {
            flag: this.flag
          }
        })
      } else if(num == 3) {
        window.open("https://twitter.com/MetaWordsNFT");
      }else if(num == 4) {
        window.open("https://t.me/MetaWordsNFTDiscussion");
      }else if(num == 5) {
        window.open("https://discord.com/invite/FFmUt3rp5J");
      }else if(num == 6) {
        window.open("https://lionnft.net/inhabitant");
      }
    },

  }
};
</script>

<style lang="scss" scoped>
// 菜单导航区域样式
.colorActive {
    color: rgba(0, 0, 0, 0.45);
  }
.menu-active-wrap {
  padding-left: 25px;
  
  span {
    display: block;
    font-size: 32px;
    font-weight: 900;
  }
   span:nth-child(1) {
     margin-top: 70px;
     width: 133px;
   }
   span:nth-child(2), span:nth-child(3) {margin-top: 24px;}
   span:nth-child(4) {margin-top: 100px; font-size: 24px;}
   span:nth-child(5) {margin-top: 24px; font-size: 24px;}
   span:nth-child(6) {margin-top: 24px; font-size: 24px;}
   span:nth-child(7) {margin-top: 60px; font-size: 24px;}
}
</style>