import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import '@/assets/font/EnFont.css';
import '@/assets/font/EnFont2.css';
import '@/assets/font/EnFont3.css';
import '@/assets/font/EnFont4.css';
import '@/assets/font/ZnFont.css'
import '@/assets/font/ZnFont2.css'
import '@/assets/font/ZnFont3.css'
import '@/assets/font/ZnFont4.css'

router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
})
createApp(App).use(router).mount('#app')
